/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {QedIcon} from 'components/page/Icon';
import SideNote from "components/page/SideNote";
function _createMdxContent(props) {
  const _components = Object.assign({
    h4: "h4",
    p: "p",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    mi: "mi",
    mo: "mo",
    annotation: "annotation",
    ul: "ul",
    li: "li",
    msub: "msub",
    mover: "mover",
    svg: "svg",
    path: "path",
    mn: "mn",
    mfrac: "mfrac",
    blockquote: "blockquote",
    msup: "msup",
    msubsup: "msubsup",
    div: "div",
    msqrt: "msqrt",
    mspace: "mspace",
    mtext: "mtext",
    mstyle: "mstyle",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h4, null, "Case: Both A and B are moving"), "\n", React.createElement(_components.p, null, "If the axis-aligned rectangles\n", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "n"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "p"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "c"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "A"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "."), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "g"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "e"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "t"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "W"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "a"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "l"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "k"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "S"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "e"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "g"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "B"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "o"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "u"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "n"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "d"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "s"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "\\tt npcA.getWalkSegBounds()")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathtt"
  }, "npcA.getWalkSegBounds"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mclose"
  }, ")")))))), " and ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "n"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "p"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "c"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "B"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "."), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "g"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "e"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "t"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "W"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "a"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "l"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "k"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "S"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "e"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "g"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "B"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "o"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "u"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "n"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "d"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "s"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "\\tt npcB.getWalkSegBounds()")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathtt"
  }, "npcB.getWalkSegBounds"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mclose"
  }, ")")))))), "\ndon't intersect, the NPCs won't collide.", React.createElement(QedIcon)), "\n", React.createElement(_components.p, null, "Otherwise for ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "ν"), React.createElement(_components.mo, null, "∈"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "{"), React.createElement(_components.mi, null, "A"), React.createElement(_components.mo, {
    separator: "true"
  }, ","), React.createElement(_components.mi, null, "B"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "}")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "\\nu \\in \\{ A, B \\}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5782em",
      verticalAlign: "-0.0391em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "∈"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mopen"
  }, "{"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"), React.createElement(_components.span, {
    className: "mpunct"
  }, ","), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.1667em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"), React.createElement(_components.span, {
    className: "mclose"
  }, "}"))))), " we have time-varying positions:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "ν")), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "r"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "ν")), React.createElement(_components.mo, null, "+"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "ν")), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mi, null, "g"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "n"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "ν"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "p_\\nu(t) := src_\\nu + t . s_\\nu . tangent_\\nu")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7333em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "sr"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8095em",
      verticalAlign: "-0.1944em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "an"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03588em"
    }
  }, "g"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span))))))))))), "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "r"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "ν")), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.mrow, null, React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "n"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "p"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "c"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "ν"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "."), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "g"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "e"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "t"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "P"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "o"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "s"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "i"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "t"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "i"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "o"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "n"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "src_\\nu := {\\tt npc\\nu.getPosition()}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5806em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "sr"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathtt"
  }, "npc"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν"), React.createElement(_components.span, {
    className: "mord mathtt"
  }, ".getPosition"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mclose"
  }, ")")))))))), "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "s"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "ν")), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.mrow, null, React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "n"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "p"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "c"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "ν"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "."), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "g"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "e"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "t"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "T"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "a"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "r"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "g"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "e"), React.createElement(_components.mi, {
    mathvariant: "monospace"
  }, "t"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "dst_\\nu := {\\tt npc\\nu.getTarget()}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8444em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathtt"
  }, "npc"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν"), React.createElement(_components.span, {
    className: "mord mathtt"
  }, ".getTarget"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mclose"
  }, ")")))))))), "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mi, null, "g"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "n"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "ν")), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.mover, {
    accent: "true"
  }, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "s"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "ν")), React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "r"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "ν"))), React.createElement(_components.mo, {
    stretchy: "true"
  }, "^"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "tangent_\\nu := \\widehat{dst_\\nu - src_\\nu}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8095em",
      verticalAlign: "-0.1944em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "an"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03588em"
    }
  }, "g"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.2644em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord accent"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "1.1144em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "sr"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))), React.createElement(_components.span, {
    className: "svg-align",
    style: {
      top: "-3.6944em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    style: {
      height: "0.42em"
    }
  }, React.createElement(_components.svg, {
    xmlns: "http://www.w3.org/2000/svg",
    width: "100%",
    height: "0.42em",
    viewBox: "0 0 2364 420",
    preserveAspectRatio: "none"
  }, React.createElement(_components.path, {
    d: "M1181 0h2l1171 296c6 0 10 5 10 11l-2 23c-1 6-5 10\n-11 10h-1L1182 67 15 340h-1c-6 0-10-4-11-10l-2-23c-1-6 4-11 10-11z"
  }))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), "\n"), "\n", React.createElement(_components.p, null, "We restrict to times ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mn, null, "0"), React.createElement(_components.mo, null, "≤"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, null, "≤"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "Ω")), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.mi, null, "m"), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mfrac, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "s"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "r"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "A"))), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "A"))), React.createElement(_components.mo, {
    separator: "true"
  }, ","), React.createElement(_components.mfrac, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "s"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "r"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "B"))), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "B"))), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "0 \\leq t \\leq t_\\Omega := min(\\frac{dst_A - src_A}{s_A}, \\frac{dst_B - src_B}{s_B})")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7804em",
      verticalAlign: "-0.136em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≤"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7719em",
      verticalAlign: "-0.136em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≤"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7651em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "Ω")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.3417em",
      verticalAlign: "-0.4453em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "min"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mopen nulldelimiter"
  }), React.createElement(_components.span, {
    className: "mfrac"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8964em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.655em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3448em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.3567em",
      marginLeft: "0em",
      marginRight: "0.0714em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.5em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size3 size1 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1433em"
    }
  }, React.createElement(_components.span))))))))), React.createElement(_components.span, {
    style: {
      top: "-3.23em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "frac-line",
    style: {
      borderBottomWidth: "0.04em"
    }
  })), React.createElement(_components.span, {
    style: {
      top: "-3.4103em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "s"), React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3448em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.3567em",
      marginLeft: "0em",
      marginRight: "0.0714em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.5em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size3 size1 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1433em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mbin mtight"
  }, "−"), React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.02778em"
    }
  }, "sr"), React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "c"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3448em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.3567em",
      marginLeft: "0em",
      marginRight: "0.0714em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.5em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size3 size1 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1433em"
    }
  }, React.createElement(_components.span)))))))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.4453em"
    }
  }, React.createElement(_components.span))))), React.createElement(_components.span, {
    className: "mclose nulldelimiter"
  })), React.createElement(_components.span, {
    className: "mpunct"
  }, ","), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.1667em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mopen nulldelimiter"
  }), React.createElement(_components.span, {
    className: "mfrac"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8964em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.655em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3448em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.3567em",
      marginLeft: "0em",
      marginRight: "0.0714em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.5em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size3 size1 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1433em"
    }
  }, React.createElement(_components.span))))))))), React.createElement(_components.span, {
    style: {
      top: "-3.23em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "frac-line",
    style: {
      borderBottomWidth: "0.04em"
    }
  })), React.createElement(_components.span, {
    style: {
      top: "-3.4103em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "s"), React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3448em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.3567em",
      marginLeft: "0em",
      marginRight: "0.0714em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.5em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size3 size1 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1433em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mbin mtight"
  }, "−"), React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.02778em"
    }
  }, "sr"), React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "c"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3448em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.3567em",
      marginLeft: "0em",
      marginRight: "0.0714em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.5em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size3 size1 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1433em"
    }
  }, React.createElement(_components.span)))))))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.4453em"
    }
  }, React.createElement(_components.span))))), React.createElement(_components.span, {
    className: "mclose nulldelimiter"
  })), React.createElement(_components.span, {
    className: "mclose"
  }, ")"))))), ".\nThat is, we only check for collisions whilst both NPCs are moving towards their target.\nAfter reaching their target, an NPC may continue towards another target or they may stop.\nAlthough we could handle these situations recursively, we prefer to handle them elsewhere."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Then to predict a collision,\nwe seek the minimal ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, null, "∈"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "["), React.createElement(_components.mn, null, "0"), React.createElement(_components.mo, {
    separator: "true"
  }, ","), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "Ω")), React.createElement(_components.mo, {
    stretchy: "false"
  }, "]")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "t \\in [0, t_\\Omega]")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6542em",
      verticalAlign: "-0.0391em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "∈"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mopen"
  }, "["), React.createElement(_components.span, {
    className: "mord"
  }, "0"), React.createElement(_components.span, {
    className: "mpunct"
  }, ","), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.1667em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "Ω")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mclose"
  }, "]"))))), " such that ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "∣"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mo, null, "−"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.msup, null, React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "∣"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "≤"), React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "s"), React.createElement(_components.msubsup, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mrow, null, React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "m"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "i"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "n")), React.createElement(_components.mn, null, "2"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "|p_A(t) - p_B(t)|^2 \\leq dist_{\\rm min}^2")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "∣"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.0641em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord"
  }, "∣"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≤"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.0786em",
      verticalAlign: "-0.2645em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.4355em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathrm mtight"
  }, "min"))))), React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2645em"
    }
  }, React.createElement(_components.span)))))))))), "."), "\n"), "\n", React.createElement(_components.p, null, "recalling ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "s"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mrow, null, React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "m"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "i"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "n")))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "dist_{\\rm min}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8444em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3175em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathrm mtight"
  }, "min")))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), " from the previous section.\nThis can be rearranged into a quadratic inequality:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.msup, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "+"), React.createElement(_components.mi, null, "b"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, null, "+"), React.createElement(_components.mi, null, "c"), React.createElement(_components.mo, null, "≤"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "a.t^2 + b.t + c ≤ 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8974em",
      verticalAlign: "-0.0833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7778em",
      verticalAlign: "-0.0833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "b"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7719em",
      verticalAlign: "-0.136em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≤"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0")))))), "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "a"), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.msubsup, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "A"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "+"), React.createElement(_components.msubsup, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "B"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "−"), React.createElement(_components.mn, null, "2."), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mi, null, "g"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "n"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, null, "⋅"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mi, null, "g"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "n"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "a := s_A^2 + s_B^2 - 2 . s_A . s_B . (tangent_A \\cdot tangent_B)")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.4306em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.0894em",
      verticalAlign: "-0.2753em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.4247em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A"))), React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2753em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.0894em",
      verticalAlign: "-0.2753em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.4247em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"))), React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2753em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "2."), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "an"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03588em"
    }
  }, "g"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "⋅"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "an"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03588em"
    }
  }, "g"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mclose"
  }, ")")))))), "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "b"), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.mn, null, "2."), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "d"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, null, "−"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "d"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "b := 2. (s_A . dp_A - s_B . dp_B)")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6944em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "b"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "2."), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mclose"
  }, ")")))))), "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "∣"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mn, null, "0"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mo, null, "−"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mn, null, "0"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.msup, null, React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "∣"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "s"), React.createElement(_components.msubsup, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mrow, null, React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "m"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "i"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "n")), React.createElement(_components.mn, null, "2"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "c := |p_B(0) - p_A(0)|^2 - dist_{\\rm min}^2")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.4306em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "∣"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord"
  }, "0"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.0641em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord"
  }, "0"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord"
  }, "∣"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.0786em",
      verticalAlign: "-0.2645em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.4355em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathrm mtight"
  }, "min"))))), React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2645em"
    }
  }, React.createElement(_components.span))))))))))), "\n"), "\n", React.createElement(_components.p, null, "where ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "ν")), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mi, null, "g"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "n"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "ν")), React.createElement(_components.mo, null, "⋅"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "r"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "r"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "dp_\\nu := tangent_\\nu \\cdot (src_A - src_B)")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8889em",
      verticalAlign: "-0.1944em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8095em",
      verticalAlign: "-0.1944em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "an"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03588em"
    }
  }, "g"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.1514em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "⋅"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "sr"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "sr"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mclose"
  }, ")"))))), " for ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "ν"), React.createElement(_components.mo, null, "∈"), React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "A"), React.createElement(_components.mo, {
    separator: "true"
  }, ","), React.createElement(_components.mi, null, "B"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "\\nu \\in { A, B }")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5782em",
      verticalAlign: "-0.0391em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.06366em"
    }
  }, "ν"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "∈"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8778em",
      verticalAlign: "-0.1944em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"), React.createElement(_components.span, {
    className: "mpunct"
  }, ","), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.1667em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))))), ".\nThen:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, null, "≥"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "dp_A \\geq 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8889em",
      verticalAlign: "-0.1944em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≥"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), " then ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "A")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "A")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"))))), " is not moving towards ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "B")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "B")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"))))), ", so the NPC's don't collide.", React.createElement(QedIcon), " ", React.createElement(SideNote, null, "Technically they may be colliding, but we allow them to separate.")), "\n", React.createElement(_components.li, null, "If ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, null, "≤"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "dp_B \\leq 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8889em",
      verticalAlign: "-0.1944em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≤"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), " then ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "B")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "B")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"))))), " is not moving towards ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "A")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "A")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"))))), ", so the NPC's don't collide.", React.createElement(QedIcon)), "\n", React.createElement(_components.li, null, "If ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mo, null, "≤"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "c \\leq 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7719em",
      verticalAlign: "-0.136em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≤"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), " the NPCs collide at ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, null, "="), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "t = 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6151em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), ".", React.createElement(QedIcon)), "\n"), "\n", React.createElement(_components.p, null, "Otherwise we seek minimal ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mn, null, "0"), React.createElement(_components.mo, null, "<"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, null, "≤"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "Ω"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "0 < t \\leq t_\\Omega")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6835em",
      verticalAlign: "-0.0391em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "<"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7719em",
      verticalAlign: "-0.136em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≤"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7651em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "Ω")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), " such that ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.msup, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "+"), React.createElement(_components.mi, null, "b"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, null, "+"), React.createElement(_components.mi, null, "c"), React.createElement(_components.mo, null, "="), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "a.t^2 + b.t + c = 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8974em",
      verticalAlign: "-0.0833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7778em",
      verticalAlign: "-0.0833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "b"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.4306em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), ".\nAny solutions take the form:"), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, React.createElement(_components.span, {
    className: "katex-display"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML",
    display: "block"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mfrac, null, React.createElement(_components.mrow, null, React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "b"), React.createElement(_components.mo, null, "±"), React.createElement(_components.msqrt, null, React.createElement(_components.mi, null, "x"))), React.createElement(_components.mrow, null, React.createElement(_components.mn, null, "2"), React.createElement(_components.mi, null, "a"))), React.createElement(_components.mspace, {
    width: "2em"
  }), React.createElement(_components.mrow, null, React.createElement(_components.mtext, null, "where "), React.createElement(_components.mstyle, {
    scriptlevel: "0",
    displaystyle: "false"
  }, React.createElement(_components.mi, null, "x"), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.msup, null, React.createElement(_components.mi, null, "b"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "−"), React.createElement(_components.mn, null, "4."), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "c"))), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, ".")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "\\frac{-b \\pm \\sqrt{x}}{2a}\n\\qquad\n\\text{where $x := b^2 - 4.a.c$}.")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "2.1633em",
      verticalAlign: "-0.686em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mopen nulldelimiter"
  }), React.createElement(_components.span, {
    className: "mfrac"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "1.4773em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.314em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord"
  }, "2"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"))), React.createElement(_components.span, {
    style: {
      top: "-3.23em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "frac-line",
    style: {
      borderBottomWidth: "0.04em"
    }
  })), React.createElement(_components.span, {
    style: {
      top: "-3.677em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord"
  }, "−"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "b"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "±"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord sqrt"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8003em"
    }
  }, React.createElement(_components.span, {
    className: "svg-align",
    style: {
      top: "-3em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord",
    style: {
      paddingLeft: "0.833em"
    }
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x"))), React.createElement(_components.span, {
    style: {
      top: "-2.7603em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "hide-tail",
    style: {
      minWidth: "0.853em",
      height: "1.08em"
    }
  }, React.createElement(_components.svg, {
    xmlns: "http://www.w3.org/2000/svg",
    width: "400em",
    height: "1.08em",
    viewBox: "0 0 400000 1080",
    preserveAspectRatio: "xMinYMin slice"
  }, React.createElement(_components.path, {
    d: "M95,702\nc-2.7,0,-7.17,-2.7,-13.5,-8c-5.8,-5.3,-9.5,-10,-9.5,-14\nc0,-2,0.3,-3.3,1,-4c1.3,-2.7,23.83,-20.7,67.5,-54\nc44.2,-33.3,65.8,-50.3,66.5,-51c1.3,-1.3,3,-2,5,-2c4.7,0,8.7,3.3,12,10\ns173,378,173,378c0.7,0,35.3,-71,104,-213c68.7,-142,137.5,-285,206.5,-429\nc69,-144,104.5,-217.7,106.5,-221\nl0 -0\nc5.3,-9.3,12,-14,20,-14\nH400000v40H845.2724\ns-225.272,467,-225.272,467s-235,486,-235,486c-2.7,4.7,-9,7,-19,7\nc-6,0,-10,-1,-12,-3s-194,-422,-194,-422s-65,47,-65,47z\nM834 80h400000v40h-400000z"
  }))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2397em"
    }
  }, React.createElement(_components.span)))))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.686em"
    }
  }, React.createElement(_components.span))))), React.createElement(_components.span, {
    className: "mclose nulldelimiter"
  })), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "2em"
    }
  }), React.createElement(_components.span, {
    className: "mord text"
  }, React.createElement(_components.span, {
    className: "mord"
  }, "where "), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "b"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "4."), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c")), React.createElement(_components.span, {
    className: "mord"
  }, ".")))))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "x"), React.createElement(_components.mo, null, "<"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "x < 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5782em",
      verticalAlign: "-0.0391em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "<"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), " there are no solutions, and the NPCs don't collide.", React.createElement(QedIcon)), "\n", React.createElement(_components.li, null, "If ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "x"), React.createElement(_components.mo, null, "="), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "x = 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.4306em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), " there is exactly one solution. It is a \"glancing collision\",\nso we may assume they do not collide.", React.createElement(QedIcon)), "\n", React.createElement(_components.li, null, "If ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "x"), React.createElement(_components.mo, null, ">"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "x > 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5782em",
      verticalAlign: "-0.0391em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ">"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), " there are two solutions.\nNow ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "a"), React.createElement(_components.mo, null, "≥"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "a \\geq 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7719em",
      verticalAlign: "-0.136em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≥"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), " always holds ", React.createElement(SideNote, null, "The lower bound ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msubsup, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "A"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "+"), React.createElement(_components.msubsup, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "B"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "−"), React.createElement(_components.mn, null, "2."), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, null, "="), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, null, "−"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "B")), React.createElement(_components.msup, null, React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mn, null, "2"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "s_A^2 + s_B^2 - 2. s_A . s_B = (s_A - s_B)^2")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.0894em",
      verticalAlign: "-0.2753em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.4247em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A"))), React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2753em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.0894em",
      verticalAlign: "-0.2753em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.4247em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"))), React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2753em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7944em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "2."), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.0641em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mclose"
  }, React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))))))))))), " is non-negative."), " and by assumption ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "c"), React.createElement(_components.mo, null, ">"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "c > 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5782em",
      verticalAlign: "-0.0391em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ">"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), ", hence ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mn, null, "0"), React.createElement(_components.mo, null, "<"), React.createElement(_components.mi, null, "x"), React.createElement(_components.mo, null, "≤"), React.createElement(_components.msup, null, React.createElement(_components.mi, null, "b"), React.createElement(_components.mn, null, "2"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "0 < x \\leq b^2")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6835em",
      verticalAlign: "-0.0391em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "<"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7719em",
      verticalAlign: "-0.136em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≤"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8141em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "b"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8141em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))))))))))), ".\nBy assumption ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, null, "<"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "dp_A < 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8889em",
      verticalAlign: "-0.1944em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "<"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), " and ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, null, ">"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "dp_B > 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8889em",
      verticalAlign: "-0.1944em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "p"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ">"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), " hence ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "b"), React.createElement(_components.mo, null, ">"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "-b > 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7778em",
      verticalAlign: "-0.0833em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "−"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "b"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ">"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), ".\nThen ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "b"), React.createElement(_components.mo, null, "−"), React.createElement(_components.msqrt, null, React.createElement(_components.mi, null, "x")), React.createElement(_components.mo, null, ">"), React.createElement(_components.mn, null, "0")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "-b - \\sqrt{x} > 0")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7778em",
      verticalAlign: "-0.0833em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "−"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "b"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.04em",
      verticalAlign: "-0.2397em"
    }
  }), React.createElement(_components.span, {
    className: "mord sqrt"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8003em"
    }
  }, React.createElement(_components.span, {
    className: "svg-align",
    style: {
      top: "-3em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord",
    style: {
      paddingLeft: "0.833em"
    }
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x"))), React.createElement(_components.span, {
    style: {
      top: "-2.7603em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "hide-tail",
    style: {
      minWidth: "0.853em",
      height: "1.08em"
    }
  }, React.createElement(_components.svg, {
    xmlns: "http://www.w3.org/2000/svg",
    width: "400em",
    height: "1.08em",
    viewBox: "0 0 400000 1080",
    preserveAspectRatio: "xMinYMin slice"
  }, React.createElement(_components.path, {
    d: "M95,702\nc-2.7,0,-7.17,-2.7,-13.5,-8c-5.8,-5.3,-9.5,-10,-9.5,-14\nc0,-2,0.3,-3.3,1,-4c1.3,-2.7,23.83,-20.7,67.5,-54\nc44.2,-33.3,65.8,-50.3,66.5,-51c1.3,-1.3,3,-2,5,-2c4.7,0,8.7,3.3,12,10\ns173,378,173,378c0.7,0,35.3,-71,104,-213c68.7,-142,137.5,-285,206.5,-429\nc69,-144,104.5,-217.7,106.5,-221\nl0 -0\nc5.3,-9.3,12,-14,20,-14\nH400000v40H845.2724\ns-225.272,467,-225.272,467s-235,486,-235,486c-2.7,4.7,-9,7,-19,7\nc-6,0,-10,-1,-12,-3s-194,-422,-194,-422s-65,47,-65,47z\nM834 80h400000v40h-400000z"
  }))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2397em"
    }
  }, React.createElement(_components.span))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ">"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"))))), ",\nso ", React.createElement(_components.em, null, "both"), " real-valued solutions are positive and ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mn, null, "0")), React.createElement(_components.mo, null, ":"), React.createElement(_components.mo, null, "="), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "b"), React.createElement(_components.mo, null, "−"), React.createElement(_components.msqrt, null, React.createElement(_components.mi, null, "x")), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mfrac, null, React.createElement(_components.mn, null, "1"), React.createElement(_components.mi, null, "a"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "t_0 := (-b - \\sqrt{x}) . \\frac{1}{a}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7651em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3011em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "0")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, ":="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord"
  }, "−"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "b"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.1901em",
      verticalAlign: "-0.345em"
    }
  }), React.createElement(_components.span, {
    className: "mord sqrt"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8003em"
    }
  }, React.createElement(_components.span, {
    className: "svg-align",
    style: {
      top: "-3em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord",
    style: {
      paddingLeft: "0.833em"
    }
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x"))), React.createElement(_components.span, {
    style: {
      top: "-2.7603em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "hide-tail",
    style: {
      minWidth: "0.853em",
      height: "1.08em"
    }
  }, React.createElement(_components.svg, {
    xmlns: "http://www.w3.org/2000/svg",
    width: "400em",
    height: "1.08em",
    viewBox: "0 0 400000 1080",
    preserveAspectRatio: "xMinYMin slice"
  }, React.createElement(_components.path, {
    d: "M95,702\nc-2.7,0,-7.17,-2.7,-13.5,-8c-5.8,-5.3,-9.5,-10,-9.5,-14\nc0,-2,0.3,-3.3,1,-4c1.3,-2.7,23.83,-20.7,67.5,-54\nc44.2,-33.3,65.8,-50.3,66.5,-51c1.3,-1.3,3,-2,5,-2c4.7,0,8.7,3.3,12,10\ns173,378,173,378c0.7,0,35.3,-71,104,-213c68.7,-142,137.5,-285,206.5,-429\nc69,-144,104.5,-217.7,106.5,-221\nl0 -0\nc5.3,-9.3,12,-14,20,-14\nH400000v40H845.2724\ns-225.272,467,-225.272,467s-235,486,-235,486c-2.7,4.7,-9,7,-19,7\nc-6,0,-10,-1,-12,-3s-194,-422,-194,-422s-65,47,-65,47z\nM834 80h400000v40h-400000z"
  }))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2397em"
    }
  }, React.createElement(_components.span))))), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mopen nulldelimiter"
  }), React.createElement(_components.span, {
    className: "mfrac"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8451em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.655em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "a")))), React.createElement(_components.span, {
    style: {
      top: "-3.23em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "frac-line",
    style: {
      borderBottomWidth: "0.04em"
    }
  })), React.createElement(_components.span, {
    style: {
      top: "-3.394em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "1"))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.345em"
    }
  }, React.createElement(_components.span))))), React.createElement(_components.span, {
    className: "mclose nulldelimiter"
  })))))), " is the smaller one.\nIf ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mn, null, "0"), React.createElement(_components.mo, null, "<"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mn, null, "0")), React.createElement(_components.mo, null, "≤"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "Ω"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "0 < t_0 \\leq t_\\Omega")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6835em",
      verticalAlign: "-0.0391em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "<"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.786em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3011em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "0")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "≤"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7651em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "Ω")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), " then the NPCs collide, otherwise they don't.", React.createElement(QedIcon), " ", React.createElement(SideNote, null, "Technically they don't collide whilst the NPCs are known to be moving (see ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "Ω"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "t_\\Omega")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7651em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "Ω")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), " above).")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
